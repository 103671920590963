import React, { Suspense, lazy, useState } from 'react';
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-01";
import PageHeader from "@containers/page-header/layout-01";
import FeatureGrid from "@components/feature-grid";
import PartnerArea from "@containers/partner/layout-01";
import IntroArea from "@components/introarea/layout-1";
import Slider3D from "@containers/global/slider-3d";
import IntroAreaReverse from "@components/introareareverse/layout-1";
import BoxSection from "@components/BoxSection/layout-one/layout-2";
import TabsSection from "@components/orangetabs-section/layout-1";
import FaqArea from "@containers/faq/layout-03";
import CtaArea from "@containers/cta/layout-02";
import CaseStudy from "@containers/global/case-study/layout-01";
import { caseStudiesData } from "@constants";
import ContactArea from "@containers/contact-us";
import StickyMenu from "@containers/sticky-menu";
import QuoteForm from "@containers/translation-quote-form";
import UseScroll from "@containers/scroll";
const Footer = lazy(() => import("@layout/footer/layout-01"))
const AppLocalizationPage = ({ location, data }) => {
  const [showRestComponents, setShowRestComponents] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const content = normalizedData(data?.page.content || []);
  const globalContent = normalizedData(data?.allGeneral.nodes || []);
  UseScroll((scrollTop) => {
    if (scrollTop > 30) {
      setShowRestComponents(true);
    } else {
      setShowRestComponents(false);
    }
  }, setIsMobile);
  return (
    <Layout location={location}>
      <Seo
        title="Premium App Translation & Localization Services"
        description="Premium App Translation & Localization Services by Andovar – Our app translation and localization services bring your apps to audiences everywhere."
        />
      <Header
        data={{
          ...globalContent["menu"],
          ...data.site.siteMetadata,
          isMobile: isMobile,
        }}
      />
      {
         !isMobile &&  <StickyMenu data={content["stycky-menu-data"]} setShowRestComponents={setShowRestComponents} showRestComponents={showRestComponents} />
      }
      <main className="site-wrapper-reveal">
        <PageHeader data={content["page-header-section"]} />
        <QuoteForm />
        {
          (!isMobile || (isMobile && showRestComponents)) && <>
             <PartnerArea data={content["app-localization-logo"]} />
             <FeatureGrid data={content["app-localization-features"]} />
             <IntroArea layout={2} data={content["app-localization-intro"]} />
          </>
        }
        {
          showRestComponents && <>
        <Slider3D data={content["app-localization-applications"]} />
        <CtaArea data={content["cta-data"]} />
        <IntroAreaReverse data={content["internationalization-body"]} />
        <TabsSection data={content["app-strategy-body"]} />
        <IntroArea data={content["app-testing-body"]} />
        <BoxSection data={content["app-markting-body"]} />
        <CaseStudy data={caseStudiesData} />
        <FaqArea data={content["app-faq-body"]} />
        <ContactArea Layout={2} />
        </>
        }
      </main>
      {
        showRestComponents && <Suspense fallback={<div></div>}>
          <Footer data={{ ...data.site.siteMetadata }} />
        </Suspense>
      }
    </Layout>
  );
};

export const query = graphql`
  query appLocalizationPageQuery {
    allGeneral {
      nodes {
        section
        ...HeaderOne
      }
    }
    site {
      ...Site
    }
    page(title: { eq: "app-localization" }, pageType: { eq: "innerpage" }) {
      content {
        ...PageContent
      }
    }
  }
`;

AppLocalizationPage.propTypes = {
  location: PropTypes.shape({}),
  data: PropTypes.shape({
    allGeneral: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        contact: PropTypes.shape({}),
      }),
    }),
    page: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

export default AppLocalizationPage;
